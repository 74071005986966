export default {
  appIsLoaded: false,
  gameId: null,
  user: null,
  company: null,
  discoverServerLocation: null,
  mainView: null,
  activeLoader: true,
  activeModules: null,
  activeMinorModule: null,
  minorActive: false,
  dropdownActive: false,
  dropdownColumnmainActive: false,
  mainContentTableColumns: null,
  dropdownColumnsidebarActive: false,
  dropdownIconSearchActive: false,
  translations: {},
  modules: null,
  activeModule: null,
  activeModuleData: null,
  activeModuleDataMapper: '',
  editActive: null,
  rowsPerPageDefault: '100',
  rowsPerPage: '100',
  activeFilters: {},
  dropdownDate: null,
  sidebarData: null,
  sidebarDataMapper: null,
  sidebarTableHeaderDropdownData: null,
  mainTableHeaderDropdownData: null,
  searchedFilter: null,
  activeSort: null,
  activeDirection: null,
  activePage: 1,
  notificationMessage: null,
  selectedRow: 0,
  offsetTime: null,
  timezone: null,
  startDate: null,
  endDate: null,
  previousDate: null,
  startTimeH: '00',
  startTimeM: '00',
  endTimeH: '23',
  endTimeM: '59',
  dateFormat: null,
  dateRangeFilterActive: 'admin_currDay',
  minorModules: {
    translations: {
      id: 'translations',
      mainTable: {
        get: 'loadSrCategoryTranslations',
        update: 'saveSrCategoryTranslation',
      },
      priority: 1,
      submodule: [],
      translation: 'general_translations',
      translationKey: 'general_translations',
      visible: true,
    },
    oddsMgmt: {
      id: 'oddsMgmt',
      mainTable: {
        get: 'getMarketsReports',
      },
      priority: 2,
      submodule: [],
      translation: 'general_oddsMgmt',
      translationKey: 'general_oddsMgmt',
      visible: true,
    },
  },
  oddsMgmtEditModeData: null,
  oddsMgmtRow: 0,
  translationsEditModeData: null,
  createNewSelection: false,
  customOfferSelectionActive: false,
  offerSelection: false,
  printSupportedTemplates: null,
  riskRulesBaseData: null,
  createRiskRule: false,
  confirmAction: false,
};
