const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */'@/pages/Home'),
    children: [
      {
        path: '/operations',
        name: 'operations',
        components: {
          mainBox: () => import(/* webpackChunkName: "mainBox" */'@/pages/MainBox'),
        },
        children: [
          {
            path: 'reports',
            name: 'reports',
            components: {
              moduleContent: () => import(/* webpackChunkName: "moduleContent" */'@/pages/ModuleContentBox'),
            },
            children: [
              {
                path: ':id',
                components: {
                  moduleContent: () => import(/* webpackChunkName: "moduleContent" */'@/pages/ModuleContentBox'),
                },
              },
            ],
            redirect: '/operations/reports/markets',
          },
          {
            path: ':id',
            components: {
              moduleContent: () => import(/* webpackChunkName: "moduleContent" */'@/pages/ModuleContentBox'),
            },
          },
        ],
        redirect: '/operations/tickets',
      },
      {
        path: '/settings',
        name: 'settings',
        components: {
          mainBox: () => import(/* webpackChunkName: "mainBox" */'@/pages/MainBox'),
        },
        children: [
          {
            path: 'edit',
            name: 'edit',
            components: {
              edit: () => import(/* webpackChunkName: "rules" */'@/pages/EditBox'),
            },
            children: [
              {
                path: 'translations',
                name: 'translations',
                components: {
                  editContent: () => import('@/pages/TranslationsEdit'),
                },
              },
              {
                path: 'oddsMgmt',
                name: 'oddsMgmt',
                components: {
                  default: () => import(/* webpackChunkName: "rules" */'@/pages/EditBox'),
                  editContent: () => import('@/pages/OddsMgmt'),
                },
              },
            ],
          },
          {
            path: 'rules',
            name: 'rules',
            components: {
              rulesBox: () => import(/* webpackChunkName: "rules" */'@/pages/RulesBox'),
            },
          },
          {
            path: ':id',
            components: {
              moduleContent: () => import(/* webpackChunkName: "moduleContent" */'@/pages/ModuleContentBox'),
            },
          },
        ],
        redirect: '/settings/categories',
      },
    ],
    redirect: '/operations/tickets',
  },
  {
    path: '*',
    redirect: '/operations/tickets',
  },
];


export default routes;
