import { merge } from 'lodash';

const createHeaders = (discoverServerLocation, user, newHeaders = null, removeHeader = null) => {
  let headers = {
    'Content-Type': 'application/json',
    'SEVEN-LOCALE': user.language,
    'HTTP-X-SEVEN-COMPANY-UUID': discoverServerLocation.companyUuid,
    'X-Nsft-Nb-Company': discoverServerLocation.companyUuid,
    'X-Nsft-Nb-Product': window.NB.gameUuid,
    Authorization: `Bearer ${user.token}`,
  };
  if (removeHeader) {
    delete headers[removeHeader];
  }
  if (newHeaders) {
    headers = merge(headers, newHeaders);
  }
  return headers;
};

export default createHeaders;
