<template>
  <div class="notification-box"
  :class="{
    'success': title === 'general_success',
    'error': title === 'general_error'
    || title === 'general_warning'}">
    <i class="n-i n-i-close"
       v-if="!removeCloseIcon"
       @click="$store.dispatch('setNotificationMessage')"></i>
    <h3>{{title | translate}}</h3>
    <p>{{description | translate}}</p>
    <div class="rules-actions"
         v-if="rulesActions">
      <div @click="sendRuleAction('save')">
        {{'admin_save_config' | translate}}
      </div>
      <div @click="sendRuleAction('cancel')">
        {{'admin_ignore_message' | translate}}
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '../store/EventBus';

export default {
  name: 'NotificationBox',
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    removeCloseIcon: {
      type: Boolean,
      default: false,
    },
    rulesActions: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    sendRuleAction(action) {
      EventBus.$emit('notification-rule-action', action);
    },
  },
};
</script>

<style scoped lang="scss">
  .notification-box {
    background-color: #009aff;
    box-shadow: 0 0 5px 0 rgba(4, 5, 5, 0.33);
    border-radius: 4px;
    bottom: 10px;
    color: #ffffff;
    font-family: primary-font;
    left: 10px;
    margin-top: 10px;
    padding: 17px 20px 10px 20px;
    position: fixed;
    width: 300px;
    z-index: 9999;
    &.success {
      background-color: #78a95d;
    }
    &.error {
      background-color: #bc0404;
    }
    i {
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 17px;
    }
    h3 {
      font-size: 18px;
      text-transform: capitalize;
    }
    p {
      margin: 15px 0;
      font-size: 14px;
    }
    p,
    .rules-actions > div, {
      font-size: 14px;
    }
    .rules-actions {
      > div {
        cursor: pointer;
        display: flex;
        &:before {
          content: "\e647";
          font-family: "SEVEN";
          padding-right: 10px;
        }
      }
    }
  }
</style>
