import httpServersDiscover from '../http';

/**
 * Fetch api location servers
 */
const serversDiscoverApi = (id, game, userToken) => {
  const apiLocationUrl = `${id}/${game}`;
  const headers = {
    'X-Nsft-Nb-Company': id,
    'X-Nsft-Nb-Product': window.NB.gameUuid,
    Authorization: `Bearer ${userToken}`,
  };
  return httpServersDiscover.get(apiLocationUrl, { headers });
};

export default serversDiscoverApi;
