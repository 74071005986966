<template>
  <div id="app">
    <template v-if="modules">
      <TemplateDefault></TemplateDefault>
    </template>
    <template v-if="notificationMessage">
      <NotificationBox
        :title="notificationMessage.title"
        :description="notificationMessage.description"
        :remove-close-icon="notificationMessage.removeCloseIcon"
        :rules-actions="notificationMessage.rulesActions"/>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TemplateDefault from '@/layouts/TemplateDefault';
import NotificationBox from './components/NotificationBox';

export default {
  name: 'App',
  components: {
    NotificationBox,
    TemplateDefault,
  },
  computed: {
    ...mapGetters([
      'modules',
      'notificationMessage',
    ]),
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/general/default.scss";
  html,
  body {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  select:not(#pagination-select) {
    /* for Firefox */
    -moz-appearance: none;
    /* for Chrome */
    -webkit-appearance: none;
    &::-ms-expand {
      display: none;
    }
  }
  .flex-center {
    align-items: center;
    display: flex;
  }
  .date-picker-date input,
  .date-picker-time input,
  .panel-date th,
  .panel-date td,
  #datepicker-range,
  .dropdown-default-box .db-actions span,
  .dropdown-default-box li,
  .dropdown-column-box li,
  .content-box.content tr,
  .select-box select,
  .select-box {
    cursor: pointer;
  }
  #app {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .flag {
    background-image: url('../public/images/flags.png');
    background-repeat: no-repeat;
    background-size: 100%;
    overflow: hidden;
    text-indent: -9999px;
  }
  .ab-button {
    border: none;
    border-radius: 2px;
    display: inline-block;
    font-family: primary-font;
    font-size: 14px !important;
    line-height: 1.42857143 !important;
    margin-bottom: 0;
    overflow: hidden;
    padding: 6px 12px !important;
    position: relative;
    touch-action: manipulation;
    text-align: center;
    user-select: none;
    white-space: nowrap;
    &:first-letter {
      text-transform: capitalize;
    }
    &.default {
      background: linear-gradient(to bottom,#929cac 0,#808b9c 100%);
      color: #fff;
      &:hover {
        background: linear-gradient(to bottom,#808b9c 0,#929cac 100%);
      }
    }
    &.primary {
      background: linear-gradient(to bottom,#2cabfe 0,#009aff 100%);
      color: #fff;
      &:not(:disabled):hover {
        background: linear-gradient(to bottom,#009aff 0,#2cabfe 100%);
      }
    }
    &.filter {
      margin: 1px;
      width: 120px;
    }
    &.rules {
      margin-left: 10px;
    }
  }
  // Datepicker - calendar override styling
  .mx-calendar {
    font-family: primary-font !important;
  }
  .mx-datepicker-popup {
    border-radius: 4px;
  }
  .mx-input {
    color: #6b7585 !important;
    height: 36px !important;
    padding: 0 40px !important;
    text-align: center;
    border: none !important;
  }
  .mx-input-append {
    right: auto !important;
  }
  .mx-input-wrapper .mx-input-append {
    left: 0;
  }
  .mx-icon-last-year,
  .mx-icon-next-year {
    display: none !important;
  }
  .mx-icon-last-month,
  .mx-icon-next-month {
    font-size: 40px !important;
    padding: 0 10px !important;
  }
  .mx-calendar-header {
    background: linear-gradient(to bottom,#929cac 0,#808b9c 100%) !important;
    color: #fff;
    height: 36px !important;
    font-size: 17px;
    padding: 0 !important;
    margin: 0 -12px;
  }
  .mx-calendar-header > a {
    display: inline-block;
    height: 100%;
  }
  .mx-calendar-header>a:hover {
    background-color: #6b7585;
    color: #fff !important;
  }
  .mx-calendar-content {
    width: 350px !important;
    height: 265px !important;
  }
  .cur-month {
    color: #000 !important;
  }
  .last-month,
  .next-month {
    color: #929cac !important;
  }
  .mx-calendar-content .cell.actived {
    color: #fff !important;
    font-family: primary-font-bold !important;
  }
  .payedout {
    border-color: #bbb04c;
    color: #bbb04c;
  }
  .lost {
    border-color: #cd6d6d;
    color: #cd6d6d;
  }
  .open {
    border-color: #009aff;
    color: #009aff;
  }
  .expired {
    border-color: #799569;
    color: #799569;
  }
  .won {
    border-color: #80c45a;
    color: #80c45a;
  }
  .closed {
    border-color: #808b9c;
    color: #808b9c;
  }
  .__rail-is-vertical {
    z-index: 3 !important;
  }
  .create-new-selection {
    .selection-date {
      margin-top: 30px;

      .mx-datepicker {
        width: 100%;

        .mx-input {
          text-align: left;
          padding-left: 10px !important;
        }

        .mx-input-append {
          background-color: #7E8B9E !important;
          color: #fff !important;
          left: auto !important;
          right: 0 !important;
        }
      }
    }
  }
  .save-row {
    display: none;
  }
  .dg.ac {
    z-index: 9999 !important;
  }
</style>
