import { pick, filter } from 'lodash';

export default {
  appIsLoaded: state => state.appIsLoaded,
  gameId: state => state.gameId,
  company: state => state.user.company,
  discoverServerLocation: state => state.discoverServerLocation,
  activeLoader: state => state.activeLoader,
  mainView: state => state.mainView,
  activeModule: state => state.activeModule,
  activeModuleData: state => state.activeModuleData,
  activeModuleDataMapper: state => state.activeModuleDataMapper,
  activeModules: state => state.activeModules,
  activeMinorModule: state => state.activeMinorModule,
  minorActive: state => state.minorActive,
  dropdownActive: state => state.dropdownActive,
  dropdownColumnmainActive: state => state.dropdownColumnmainActive,
  mainContentTableColumns: state => state.mainContentTableColumns,
  dropdownColumnsidebarActive: state => state.dropdownColumnsidebarActive,
  dropdownIconSearchActive: state => state.dropdownIconSearchActive,
  translations: state => state.translations,
  translate: state => key => state.translations[key] || key,
  modules: state => state.modules,
  editActive: state => state.editActive,
  activeFilters: state => state.activeFilters,
  rowsPerPage: state => state.rowsPerPage,
  sidebarData: state => state.sidebarData,
  sidebarDataMapper: state => state.sidebarDataMapper,
  user: state => state.user,
  mainTableHeaderDropdownData: state => state.mainTableHeaderDropdownData,
  sidebarTableHeaderDropdownData: state => state.sidebarTableHeaderDropdownData,
  searchedFilter: state => state.searchedFilter,
  activeSort: state => state.activeSort,
  activeDirection: state => state.activeDirection,
  activePage: state => state.activePage,
  notificationMessage: state => state.notificationMessage,
  selectedRow: state => state.selectedRow,
  startDate: state => state.startDate,
  endDate: state => state.endDate,
  startTimeH: state => state.startTimeH,
  startTimeM: state => state.startTimeM,
  endTimeH: state => state.endTimeH,
  endTimeM: state => state.endTimeM,
  dateFormat: state => state.dateFormat,
  timezone: state => state.user.timezone,
  dateRangeFilterActive: state => state.dateRangeFilterActive,
  previousDate: state => state.previousDate,
  minorModules: state => (state.activeModule.id === 'drawings' ? state.minorModules
    : pick(state.minorModules, ['translations'])),
  oddsMgmtEditModeData: state => state.oddsMgmtEditModeData,
  oddsMgmtRow: state => state.oddsMgmtRow,
  translationsEditModeData: state => state.translationsEditModeData,
  createNewSelection: state => state.createNewSelection,
  language: state => state.user.language,
  customOfferSelectionActive: state => state.customOfferSelectionActive,
  printSupportedTemplates: state => key => filter(state.printSupportedTemplates,
    template => template.template_selection === key),
  createRiskRule: state => state.createRiskRule,
  confirmAction: state => state.confirmAction,
};
