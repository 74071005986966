import axios from 'axios';

/*
Change baseURL to suit your project needs.
*/
const httpServersDiscover = axios.create({
  baseURL: process.env.VUE_APP_API_LOCATION_URL,
  timeout: 5000,
});

export default httpServersDiscover;
