export default {
  SET_APP_IS_LOADED: 'SET_APP_IS_LOADED',
  SET_GAME_ID: 'SET_GAME_ID',
  SET_USER: 'SET_USER',
  SET_DISCOVER_SERVER_LOCATION: 'SET_DISCOVER_SERVER_LOCATION',
  SET_ACTIVE_LOADER: 'SET_ACTIVE_LOADER',
  SET_MAIN_VIEW: 'SET_MAIN_VIEW',
  SET_ACTIVE_MODULE: 'SET_ACTIVE_MODULE',
  SET_ACTIVE_MODULE_DATA: 'SET_ACTIVE_MODULE_DATA',
  SET_MODULES: 'SET_MODULES',
  SET_ACTIVE_MODULES: 'SET_ACTIVE_MODULES',
  SET_ACTIVE_MINOR_MODULES: 'SET_ACTIVE_MINOR_MODULES',
  SET_MINOR_ACTIVE: 'SET_MINOR_ACTIVE',
  SET_DROPDOWN_STATE: 'SET_DROPDOWN_STATE',
  SET_DROPDOWN_COLUMN_MAIN_STATE: 'SET_DROPDOWN_COLUMN_MAIN_STATE',
  SET_DROPDOWN_COLUMN_SIDEBAR_STATE: 'SET_DROPDOWN_COLUMN_SIDEBAR_STATE',
  SET_DROPDOWN_ICON_SEARCH_STATE: 'SET_DROPDOWN_ICON_SEARCH_STATE',
  SET_EDIT: 'SET_EDIT',
  SET_ROWS_PER_PAGE: 'SET_ROWS_PER_PAGE',
  SET_ACTIVE_FILTERS: 'SET_ACTIVE_FILTERS',
  SET_DROPDOWN_DATE: 'SET_DROPDOWN_DATE',
  SET_SIDEBAR_DATA: 'SET_SIDEBAR_DATA',
  SET_MAIN_CONTENT_TABLE_COLUMNS: 'SET_MAIN_CONTENT_TABLE_COLUMNS',
  SET_MAIN_TABLE_HEADER_DROPDOWN_DATA: 'SET_MAIN_TABLE_HEADER_DROPDOWN_DATA',
  SET_SIDEBAR_TABLE_HEADER_DROPDOWN_DATA: 'SET_SIDEBAR_TABLE_HEADER_DROPDOWN_DATA',
  SET_SEARCHED_FILTER: 'SET_SEARCHED_FILTER',
  SET_ACTIVE_SORT: 'SET_ACTIVE_SORT',
  SET_ACTIVE_DIRECTION: 'SET_ACTIVE_DIRECTION',
  SET_ACTIVE_PAGINATION_PAGE: 'SET_ACTIVE_PAGINATION_PAGE',
  RESET_ACTIVE_PAGINATIONS_STATE: 'RESET_ACTIVE_PAGINATIONS_STATE',
  SET_NOTIFICATION_MESSAGE: 'SET_NOTIFICATION_MESSAGE',
  SET_SELECTED_ROW: 'SET_SELECTED_ROW',
  SET_TRANSLATIONS: 'SET_TRANSLATIONS',
  SET_DATE_TIME: 'SET_DATE_TIME',
  RESET_TIME: 'RESET_TIME',
  RESET_DATE: 'RESET_DATE',
  SET_DATE_RANGE_FILTER_ACTIVE: 'SET_DATE_RANGE_FILTER_ACTIVE',
  SET_PREVIOUS_DATE: 'SET_PREVIOUS_DATE',
  SET_ODDS_MGMT_EDIT_MODE_DATA: 'SET_ODDS_MGMT_EDIT_MODE_DATA',
  SET_ODDS_MGMT_ROW: 'SET_ODDS_MGMT_ROW',
  SET_TRANSLATIONS_EDIT_MODE_DATA: 'SET_TRANSLATIONS_EDIT_MODE_DATA',
  UPDATE_EDIT_MODE_DATA: 'UPDATE_EDIT_MODE_DATA',
  SET_CREATE_NEW_SELECTION: 'SET_CREATE_NEW_SELECTION',
  SET_CUSTOM_OFFER_SELECTION_STATUS: 'SET_CUSTOM_OFFER_SELECTION_STATUS',
  SET_PRINT_SUPPORTED_TEMPLATES: 'SET_PRINT_SUPPORTED_TEMPLATES',
  SET_RISK_RULES_BASE_DATA: 'SET_RISK_RULES_BASE_DATA',
  SET_CREATE_RISK_RULE: 'SET_CREATE_RISK_RULE',
  SET_CONFIRM_ACTION: 'SET_CONFIRM_ACTION',
  SET_CREATE_RISK_ACTIVE: 'SET_CREATE_RISK_ACTIVE',
};
