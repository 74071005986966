import axios from 'axios';

const moduleNavigationsApi = (url, module, gameid, companyUuid, userToken) => {
  const headers = {
    'X-Nsft-Nb-Company': companyUuid,
    'X-Nsft-Nb-Product': window.NB.gameUuid,
    Authorization: `Bearer ${userToken}`,
  };
  return axios.get(`${url}${module}/${gameid}`, { headers });
};

export default moduleNavigationsApi;
