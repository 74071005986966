import {
  indexOf,
  find,
  toString,
} from 'lodash';
import moment from 'moment';

const findIndex = (group, name) => indexOf(group, name);

const findObject = (group, id) => find(group, item => item.id === id);

const modulDataQueryParams = (state, payload) => {
  const parseDate = {
    startDate: state.mainView === 'operations'
      ? `&startDate=${moment(moment(state.startDate, state.dateFormat).toDate()).format('YYYY-MM-DD')}*${state.startTimeH}:${state.startTimeM}:00` : '',
    endDate: state.mainView === 'operations'
      ? `&endDate=${moment(moment(state.endDate, state.dateFormat).toDate()).format('YYYY-MM-DD')}*${state.endTimeH}:${state.endTimeM}:00` : '',
  };
  const reset = payload && payload.reset;
  let count;
  if (!reset) {
    count = state.rowsPerPage === 'all' ? '5000' : state.rowsPerPage;
  } else {
    count = state.rowsPerPageDefault;
  }
  const page = payload && payload.page && !reset ? payload.page : 1;
  const search = state.searchedFilter ? `&search=${state.searchedFilter}` : '';
  const sort = payload && payload.sort ? `&sort=${payload.sort}` : '';
  const direction = payload && payload.direction ? `&direction=${payload.direction}` : '';
  const timezone = state.mainView === 'operations' ? `&timezone=${state.timezone}` : '';
  const offsetTime = state.mainView === 'operations' ? `&offsetTime=${state.offsetTime}` : '';
  let queryParams = `?count=${count}&page=${page}${search}${parseDate.startDate}${parseDate.endDate}${sort}${direction}${timezone}${offsetTime}`;
  if (Object.keys(state.activeFilters).length) {
    let activeFiltersQueryParams = '';
    Object.entries(state.activeFilters).forEach((value) => {
      if (value[1].length) {
        activeFiltersQueryParams += `&${value[0]}=${toString(value[1])}`;
      }
    });
    queryParams += activeFiltersQueryParams;
  }
  return queryParams;
};
const getMaxRtpValidation = (value, type) => {
  let maxRtp;
  let typeOfBet = 'special';
  switch (type) {
    case '832':
    case '833':
    case '834':
      typeOfBet = 'standard';
      maxRtp = 100;
      break;
    default:
      maxRtp = 99;
      break;
  }
  return {
    valid: parseFloat(value) < maxRtp,
    maxRtp,
    typeOfBet,
  };
};

export {
  findIndex,
  findObject,
  modulDataQueryParams,
  getMaxRtpValidation,
};
