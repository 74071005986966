import Gateway from '@nsftx/seven-gravity-gateway/slave';
import types from '@/store/mutationTypes';
import i18n from '@/i18n';

let gateway = {};

/**
 * Send message to master
 *
 * @param {String} action
 * @param {Object} data
 */
function sendMessage(action, data) {
  gateway.sendMessage({
    action,
    data,
  });
}
/**
 * Search for query params of game/product Id
 *
 * @param {String} name - Name of query (game)
 * @returns {string}
 */
function getParam(name) {
  const url = window.location.href;
  const index = url.indexOf('?');
  let value;
  if (index >= 0) {
    const params = url.split('?')[1].split('&');
    const paramsObj = {};
    let i = 0;
    while (i < params.length) {
      const splited = params[i].split('=');
      const [first, second] = splited;
      paramsObj[first] = second;
      i += 1;
    }
    if (Object.prototype.hasOwnProperty.call(paramsObj, name)) {
      value = paramsObj[name];
    }
  }
  return value;
}
/**
 * Initialize gateway communication
 */
function initGateway(store) {
  const slaveId = getParam('slaveId');
  gateway = Gateway({
    debug: true,
    slaveId,
    allowedOrigins: [
      'http://localhost:9090',
      'http://admin-local.7platform.com:8080',
      'https://admin-dev.7platform.com',
      'https://admin-staging.7platform.com',
      'https://admin.7platform.com',
      'https://admin.7platform.net',
      'https://admin-rs.7platform.com',
      'https://admin-rs.7platform.net',
      'https://admin-ro.7platform.com',
      'https://admin-ro.7platform.net',
      'https://admin-ro2.7platform.net',
      'https://dev.admin.7platform.net',
      'https://staging.admin.7platform.net',
      'https://admin-rs2.7platform.com',
      'https://admin-rs2.7platform.net',
      'https://admin-de.7platform.com',
      'https://admin-de.7platform.net',
    ],
    load: async (message) => {
      gateway.sendMessage({
        action: 'Slave.Loaded',
        data: {},
      });
      const routePathSplited = message.data.route.split('/');
      const gameId = routePathSplited[2];
      window.NB = {};
      // eslint-disable-next-line prefer-destructuring
      window.NB.gameUuid = routePathSplited[3];
      store.commit(types.SET_GAME_ID, gameId);
      const locale = message.data.user.language;
      const translations = (await i18n(locale)).default;
      store.commit('SET_TRANSLATIONS', translations);
      store.commit(types.SET_USER, message.data.user);
      store.commit('SET_APP_IS_LOADED', true);
    },
    eventListeners: {
      scroll: true,
    },
  });
}

function getGateway() {
  return gateway;
}
export {
  initGateway,
  sendMessage,
  getParam,
  getGateway,
};
