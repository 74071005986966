import Vue from 'vue';
import types from './mutationTypes';

const moment = require('moment-timezone');

export default {
  [types.SET_APP_IS_LOADED](state, payload) {
    state.appIsLoaded = payload;
  },
  [types.SET_GAME_ID](state, payload) {
    state.gameId = payload;
  },
  [types.SET_USER](state, payload) {
    state.user = payload;
    if (payload) {
      state.timezone = payload.timezone;
      state.offsetTime = payload.timezoneOffset;
    }
    state.dateFormat = payload && payload.momentDateFormat
      ? payload.momentDateFormat : 'YYYY-MM-DD';
  },
  [types.SET_DISCOVER_SERVER_LOCATION](state, payload) {
    state.discoverServerLocation = payload;
  },
  [types.SET_ACTIVE_LOADER](state, payload) {
    state.activeLoader = payload;
  },
  [types.SET_MAIN_VIEW](state, payload) {
    state.mainView = payload;
  },
  [types.SET_MODULES](state, payload) {
    state.modules = payload;
  },
  [types.SET_ACTIVE_MODULE](state, payload) {
    state.activeModule = null;
    state.activeModule = payload;
    state.dropdownActive = false;
    state.dropdownColumnmainActive = false;
    state.dropdownColumnsidebarActive = false;
    state.dropdownIconSearchActive = false;
  },
  [types.SET_ACTIVE_MODULE_DATA](state, payload) {
    state.activeModuleData = null;
    state.activeModuleDataMapper = null;
    if (payload) {
      state.activeModuleData = payload;
      state.activeModuleDataMapper = JSON.stringify(payload);
    }
  },
  [types.SET_ACTIVE_MODULES](state, payload) {
    state.activeModules = payload;
  },
  [types.SET_ACTIVE_MINOR_MODULES](state, payload) {
    state.activeMinorModule = payload;
  },
  [types.SET_MINOR_ACTIVE](state, payload) {
    state.minorActive = payload;
  },
  [types.SET_DROPDOWN_STATE](state, payload) {
    state.dropdownActive = payload;
    state.dropdownColumnmainActive = false;
    state.dropdownColumnsidebarActive = false;
    state.dropdownIconSearchActive = false;
  },
  [types.SET_DROPDOWN_COLUMN_MAIN_STATE](state, payload) {
    state.dropdownColumnmainActive = payload;
    state.dropdownActive = false;
    state.dropdownColumnsidebarActive = false;
    state.dropdownIconSearchActive = false;
  },
  [types.SET_DROPDOWN_COLUMN_SIDEBAR_STATE](state, payload) {
    state.dropdownColumnsidebarActive = payload;
    state.dropdownActive = false;
    state.dropdownColumnmainActive = false;
    state.dropdownIconSearchActive = false;
  },
  [types.SET_DROPDOWN_ICON_SEARCH_STATE](state, payload) {
    state.dropdownIconSearchActive = payload;
    state.dropdownActive = false;
    state.dropdownColumnsidebarActive = false;
    state.dropdownColumnmainActive = false;
  },
  [types.SET_EDIT](state, payload) {
    state.editActive = !payload ? null : payload;
  },
  [types.SET_ROWS_PER_PAGE](state, payload) {
    state.rowsPerPage = payload;
  },
  [types.SET_ACTIVE_FILTERS](state, payload) {
    state.activeFilters = payload;
  },
  [types.SET_DROPDOWN_DATE](state, payload) {
    state.dropdownDate = payload;
  },
  [types.SET_SIDEBAR_DATA](state, payload) {
    state.sidebarData = null;
    state.sidebarDataMapper = null;
    if (payload) {
      state.sidebarData = payload;
      state.sidebarDataMapper = JSON.stringify(payload);
    }
  },
  [types.SET_MAIN_CONTENT_TABLE_COLUMNS](state, payload) {
    state.mainContentTableColumns = payload;
  },
  [types.SET_MAIN_TABLE_HEADER_DROPDOWN_DATA](state, payload) {
    state.mainTableHeaderDropdownData = !payload ? null : payload;
  },
  [types.SET_SIDEBAR_TABLE_HEADER_DROPDOWN_DATA](state, payload) {
    state.sidebarTableHeaderDropdownData = !payload ? null : payload;
  },
  [types.SET_SEARCHED_FILTER](state, payload) {
    state.searchedFilter = !payload ? null : payload;
  },
  [types.SET_ACTIVE_SORT](state, payload) {
    state.activeSort = !payload ? null : payload;
  },
  [types.SET_ACTIVE_DIRECTION](state, payload) {
    state.activeDirection = !payload ? null : payload;
  },
  [types.SET_ACTIVE_PAGINATION_PAGE](state, payload) {
    state.activePage = payload;
  },
  [types.RESET_ACTIVE_PAGINATIONS_STATE](state) {
    state.activePage = 1;
    state.activeDirection = null;
    state.activeSort = null;
  },
  [types.SET_NOTIFICATION_MESSAGE](state, payload) {
    state.notificationMessage = payload;
  },
  [types.SET_SELECTED_ROW](state, payload) {
    state.selectedRow = payload;
  },
  [types.SET_TRANSLATIONS](state, payload) {
    state.translations = payload;
  },
  [types.SET_DATE_TIME](state, payload) {
    state[payload.prop] = payload.value;
  },
  [types.RESET_TIME](state) {
    state.startTimeH = '00';
    state.startTimeM = '00';
    state.endTimeH = '23';
    state.endTimeM = '59';
  },
  [types.RESET_DATE](state) {
    // eslint-disable-next-line prefer-destructuring
    state.startDate = moment.tz(state.timezone).format(state.dateFormat);
    // eslint-disable-next-line prefer-destructuring
    state.endDate = moment.tz(state.timezone).format(state.dateFormat);
  },
  [types.SET_DATE_RANGE_FILTER_ACTIVE](state, payload) {
    state.dateRangeFilterActive = payload;
  },
  [types.SET_PREVIOUS_DATE](state, payload) {
    state.previousDate = payload;
  },
  [types.SET_ODDS_MGMT_EDIT_MODE_DATA](state, payload) {
    state.oddsMgmtEditModeData = payload;
  },
  [types.SET_ODDS_MGMT_ROW](state, payload) {
    state.oddsMgmtRow = payload;
  },
  [types.SET_TRANSLATIONS_EDIT_MODE_DATA](state, payload) {
    state.translationsEditModeData = payload;
  },
  [types.UPDATE_EDIT_MODE_DATA](state, payload) {
    if (state.activeMinorModule === 'oddsMgmt') {
      Vue.set(state.oddsMgmtEditModeData, payload.prop, payload.data);
    } else {
      Vue.set(state.translationsEditModeData[payload.index], payload.prop, payload.data);
    }
  },
  [types.SET_CREATE_NEW_SELECTION](state, payload) {
    state.createNewSelection = payload;
  },
  [types.SET_CUSTOM_OFFER_SELECTION_STATUS](state, payload) {
    state.customOfferSelectionActive = payload;
  },
  [types.SET_PRINT_SUPPORTED_TEMPLATES](state, payload) {
    state.printSupportedTemplates = payload;
  },
  [types.SET_RISK_RULES_BASE_DATA](state, payload) {
    state.riskRulesBaseData = payload;
  },
  [types.SET_CREATE_RISK_RULE](state, payload) {
    state.createRiskRule = payload;
  },
  [types.SET_CONFIRM_ACTION](state, payload) {
    state.confirmAction = payload;
  },
};
