import Vue from 'vue';
import vuescroll from 'vuescroll/dist/vuescroll-native.min';
// import the css file
import 'vuescroll/dist/vuescroll.css';
import clickOutside from './directives';
import App from './App';
import createRouter from './router';
import routes from './router/routes';
import store from './store';
import serversDiscoverApi from './api/interceptors/serversDiscoverApi';
import moduleNavigationsApi from './api/interceptors/moduleNavigationsApi';
import './filters';
import { initGateway } from './services/gateway';

initGateway(store);

Vue.config.productionTip = false;
Vue.use(vuescroll, {
  ops: {
    bar: {
      background: 'rgb(0, 0, 0)',
      opacity: '.25',
    },
  },
});
Vue.directive('click-outside', clickOutside);

store.watch(state => state.appIsLoaded, () => {
  const gameid = store.getters.gameId;
  new Vue({
    router: createRouter(routes),
    store,
    beforeCreate() {
      const mainView = window.location.pathname.split('/')[1] || 'operations';
      store.commit('SET_MAIN_VIEW', mainView);
      store.dispatch('resetDateTime');
      serversDiscoverApi(store.getters.company.uuid, gameid, store.getters.user.token)
        .then((serversDiscoverApiResponse) => {
          store.commit('SET_DISCOVER_SERVER_LOCATION', serversDiscoverApiResponse.data);
          moduleNavigationsApi(`${serversDiscoverApiResponse.data.productApi.url}${process.env.VUE_APP_MODULE_NAV}`, mainView, gameid, store.getters.company.uuid, store.getters.user.token).then((moduleNavigationsApiResponse) => {
            // eslint-disable-next-line no-undef
            const visibleNav = filter(moduleNavigationsApiResponse.data.modules,
              nav => nav.visible);
            store.commit('SET_MODULES', visibleNav);
          });
        });
    },
    render: h => h(App),
  }).$mount('#app');
});
