const clickOutside = {
  // eslint-disable-next-line func-names
  bind(el, binding, vnode) {
    // eslint-disable-next-line func-names,no-param-reassign
    el.clickOutsideEvent = function (event) {
      event.stopPropagation();
      const type = binding.value;
      setTimeout(() => {
        vnode.context.$store.dispatch(type, false);
      }, 100);
    };
    el.addEventListener('click', (event) => {
      event.stopPropagation();
    });
    setTimeout(() => {
      document.body.addEventListener('click', el.clickOutsideEvent);
    }, 100);
  },
  unbind(el) {
    el.removeEventListener('click', (event) => {
      event.stopPropagation();
    });
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
};
export default clickOutside;
