import axios from 'axios';
import forEach from 'lodash/forEach';
import { getEdit, updateEdit } from '@/api';
import * as utility from '../utility';
import createHeaders from '../api/utility';
import EventBus from '@/store/EventBus';

const axiosSource = {
  axiosMainSource: null,
  axiosSidebarSource: null,
};

let notificationTimeout;
function checkAxiosSource(name = 'axiosMainSource') {
  if (axiosSource[name]) {
    axiosSource[name].cancel('Start new requeat, stop spam');
  }
  axiosSource[name] = axios.CancelToken.source();
}

export default {
  setAppIsLoaded: ({ commit }, payload) => {
    commit('SET_APP_LOADED', payload);
  },
  setActiveLoader: ({ commit }, payload) => {
    commit('SET_ACTIVE_LOADER', payload);
  },
  setDiscoverServerLocations: ({ commit }, payload) => {
    commit('SET_DISCOVER_SERVER_LOCATION', payload);
  },
  setMainView: ({ commit }, payload) => {
    commit('SET_MAIN_VIEW', payload);
  },
  callCheckAxiosSource: () => {
    checkAxiosSource();
  },
  setActiveModules: ({ commit, dispatch, state }, payload) => {
    checkAxiosSource();
    dispatch('setDateRangeFilterActive', 'admin_currDay');
    dispatch('setPreviousDate', null);
    if (axiosSource.axiosSidebarSource) {
      axiosSource.axiosSidebarSource.cancel('Stop sidebar request');
    }
    // firstRequest = true;
    dispatch('resetActivePaginationsState');
    commit('SET_ACTIVE_MODULES', payload);
    commit('SET_ACTIVE_MODULE_DATA');
    const module = utility.findObject(state.modules, payload);
    commit('SET_ACTIVE_MODULE', module);
    dispatch('setSelectedRow', 0);
    if (module.submodule.length) {
      dispatch('setMinorActive', true);
      dispatch('setActiveMinorModule', module.submodule[0].id);
    } else {
      dispatch('setMinorActive', false);
    }
    commit('SET_ROWS_PER_PAGE', state.rowsPerPageDefault);
    if (!state.activeModule.submodule.length && module.id !== 'rules') {
      if (state.activeModule.id === 'results') {
        dispatch('fetchActiveModuleData', { direction: 'desc', sort: 'draw_result_time' });
      } else {
        dispatch('fetchActiveModuleData');
      }
    }
  },
  fetchActiveModuleData: async ({ commit, dispatch, state }, payload) => {
    if (payload && payload.axiosSource) {
      checkAxiosSource();
    }
    dispatch('setActiveLoader', true);
    // payload will be added after we implement real API
    commit('SET_SIDEBAR_DATA');
    const headers = createHeaders(state.discoverServerLocation, state.user);
    if (state.activeModule.id === 'results') {
      // eslint-disable-next-line no-param-reassign
      payload = !payload ? {} : payload;
      // eslint-disable-next-line no-param-reassign
      payload.direction = payload.direction ? payload.direction : 'desc';
      // eslint-disable-next-line no-param-reassign
      payload.sort = payload.sort ? payload.sort : 'draw_result_time';
    }
    const queryParams = utility.modulDataQueryParams(state, payload);
    try {
      const response = await axios.get(`${state.discoverServerLocation.productApi.url}/${state.activeModule.mainTable.get}/${state.gameId}${queryParams}`,
        { cancelToken: axiosSource.axiosMainSource.token, headers });
      commit('SET_MAIN_CONTENT_TABLE_COLUMNS', response.data.columns);
      if (state.activeModule.sideBar && response.data.data.length) {
        axiosSource.axiosMainSource = null;
        dispatch('fetchSidebarData', response.data.data[0].id);
      } else {
        commit('SET_SIDEBAR_DATA', null);
        dispatch('setActiveLoader', false);
      }
      if (!response.data.data.length) {
        dispatch('setNotificationMessage', {
          title: 'general_info',
          description: 'admin_noDataForSelectionParametars',
          rulesActions: false,
        });
      }
      commit('SET_ACTIVE_MODULE_DATA', response.data);
    } catch (e) {
      console.log(e);
    }
  },
  fetchSidebarData: async ({ commit, dispatch, state }, payload) => {
    checkAxiosSource('axiosSidebarSource');
    const headers = createHeaders(state.discoverServerLocation, state.user);
    try {
      const sidebarResponse = await axios.get(`${state.discoverServerLocation.productApi.url}/${state.activeModule.sideBar.get}/${state.gameId}?id=${payload}&offsetTime=${state.offsetTime}&timezone=${state.timezone}`,
        { cancelToken: axiosSource.axiosSidebarSource.token, headers });
      if (sidebarResponse.data) {
        const sidebarData = sidebarResponse.data;
        if (state.activeModule.id === 'tickets') {
          forEach(sidebarData.data, (betData) => {
            if (betData.systemBetting) {
              const playedNumbersLength = betData.playedNumbers.split(',').length;
              const { bankers, systemTypes } = betData.systemBetting;
              const bankersLength = bankers ? bankers.length : 0;
              const system = playedNumbersLength - bankersLength;
              const type = betData.systemBetting.systemTypes.length === 1
                ? systemTypes.toString() : `(${systemTypes.toString()})`;
              sidebarData.additionalTitles.bankers = 'Bankers';
              // eslint-disable-next-line no-param-reassign
              betData.bankers = betData.systemBetting.bankers
                ? betData.systemBetting.bankers.join() : '';
              // eslint-disable-next-line no-param-reassign
              betData.bet_name = `System ${type}/${system}`;
              // eslint-disable-next-line no-param-reassign
              betData.odd = '-';
            }
          });
        }
        axiosSource.axiosSidebarSource = null;
        commit('SET_SIDEBAR_DATA', sidebarData);
      } else if (!sidebarResponse || !sidebarResponse.data.length) {
        dispatch('setNotificationMessage', {
          title: 'general_info',
          description: 'admin_noDataForSelectionParametars',
          rulesActions: false,
        });
      }
      dispatch('setActiveLoader', false);
    } catch (e) {
      console.log(e);
    }
  },
  fetchEditModules: ({ commit, dispatch, state }) => {
    dispatch('setActiveLoader', true);
    const headers = createHeaders(state.discoverServerLocation, state.user);
    let type = null;
    checkAxiosSource();
    let prop = 'id';
    let url = null;
    switch (state.activeModule.id) {
      case 'outcomes':
        prop += '_outcome';
        break;
      case 'markets':
        prop += '_bet';
        break;
      // no default
    }
    if (state.activeMinorModule === 'translations') {
      url = `${state.discoverServerLocation.productApi.url}/${state.activeModule.mainTable.popup.get}/${state.gameId}?${prop}=${state.editActive.selectionData.id}`;
      type = 'SET_TRANSLATIONS_EDIT_MODE_DATA';
    } else {
      url = `${state.discoverServerLocation.productApi.url}/client/odds/${state.editActive.selectionData.id}`;
      type = 'SET_ODDS_MGMT_EDIT_MODE_DATA';
    }
    getEdit(url, headers).then((response) => {
      commit(type, response.data);
    });
    dispatch('setActiveLoader', false);
  },
  setActiveMinorModule: ({ commit, dispatch, state }, payload) => {
    if (payload && state.activeMinorModule !== payload) {
      dispatch('setEditModeData');
      dispatch('setNotificationMessage');
      commit('SET_ACTIVE_MINOR_MODULES', payload);
      dispatch('fetchEditModules');
    } else if (!payload) {
      commit('SET_ACTIVE_MINOR_MODULES', null);
    }
  },
  setMinorActive: ({ commit }, payload) => {
    commit('SET_MINOR_ACTIVE', payload);
  },
  setDropdownState: ({ commit }, payload) => {
    commit('SET_DROPDOWN_STATE', payload);
  },
  setDropdownColumnmainState: ({ commit }, payload) => {
    commit('SET_DROPDOWN_COLUMN_MAIN_STATE', payload);
  },
  setDropdownColumnsidebarState: ({ commit }, payload) => {
    commit('SET_DROPDOWN_COLUMN_SIDEBAR_STATE', payload);
  },
  setDropdownIconSearchState: ({ commit }, payload) => {
    commit('SET_DROPDOWN_ICON_SEARCH_STATE', payload);
  },
  setEdit: ({ commit }, payload) => {
    commit('SET_EDIT', payload);
  },
  setActiveFilters: ({ commit }, payload) => {
    commit('SET_ACTIVE_FILTERS', payload);
  },
  setRowsPerPage: ({ commit, dispatch }, payload) => {
    checkAxiosSource();
    commit('SET_ROWS_PER_PAGE', payload);
    dispatch('setActivePaginationPage', 1);
    dispatch('fetchActiveModuleData');
  },
  setDataFromRange: ({ dispatch }) => {
    checkAxiosSource();
    dispatch('setActivePaginationPage', 1);
    dispatch('fetchActiveModuleData');
  },
  setSidebarData: ({ commit }, payload) => {
    commit('SET_SIDEBAR_DATA', payload);
  },
  resetFilters: ({ dispatch }) => {
    checkAxiosSource();
    dispatch('setSearchedFilter', { value: null, type: 'reset' });
    dispatch('setActiveFilters', {});
    dispatch('setActivePaginationPage', 1);
    dispatch('fetchActiveModuleData', { reset: true });
  },
  setMainContentTableColumns: ({ commit }, payload) => {
    commit('SET_MAIN_CONTENT_TABLE_COLUMNS', payload);
  },
  set_mainTableHeaderDropdownData: ({ commit }, payload) => {
    commit('SET_MAIN_TABLE_HEADER_DROPDOWN_DATA', payload);
  },
  set_sidebarTableHeaderDropdownData: ({ commit }, payload) => {
    commit('SET_SIDEBAR_TABLE_HEADER_DROPDOWN_DATA', payload);
  },
  setSearchedFilter: ({ commit, dispatch, state }, payload) => {
    commit('SET_SEARCHED_FILTER', payload.value);
    if (payload.type !== 'reset') {
      if (!state.activeMinorModule) {
        checkAxiosSource();
        dispatch('setActivePaginationPage', 1);
        dispatch('fetchActiveModuleData');
      } else {
        dispatch('fetchEditModules');
      }
    }
  },
  setActiveSort: ({ commit }, payload) => {
    commit('SET_ACTIVE_SORT', payload);
  },
  setActiveDirection: ({ commit }, payload) => {
    commit('SET_ACTIVE_DIRECTION', payload);
  },
  setActivePaginationPage: ({ commit }, payload) => {
    commit('SET_ACTIVE_PAGINATION_PAGE', payload);
  },
  resetActivePaginationsState: ({ commit }, payload) => {
    commit('RESET_ACTIVE_PAGINATIONS_STATE', payload);
  },
  setNotificationMessage: ({ commit, dispatch }, payload) => {
    clearTimeout(notificationTimeout);
    commit('SET_NOTIFICATION_MESSAGE', payload);
    if (payload && !payload.rulesActions && !payload.removeTimeout) {
      notificationTimeout = setTimeout(() => {
        dispatch('setNotificationMessage');
      }, 10000);
    }
  },
  setSelectedRow: ({ commit }, payload) => {
    commit('SET_SELECTED_ROW', payload);
  },
  setDateTime: ({ commit }, payload) => {
    commit('SET_DATE_TIME', payload);
  },
  resetTime: ({ commit }) => {
    commit('RESET_TIME');
  },
  resetDate: ({ commit }) => {
    commit('RESET_DATE');
  },
  resetDateTime: ({ commit }) => {
    commit('RESET_DATE');
    commit('RESET_TIME');
  },
  setDateRangeFilterActive: ({ commit }, payload) => {
    commit('SET_DATE_RANGE_FILTER_ACTIVE', payload);
  },
  setPreviousDate: ({ commit }, payload) => {
    commit('SET_PREVIOUS_DATE', payload);
  },
  setEditModeData: ({ commit }) => {
    commit('SET_TRANSLATIONS_EDIT_MODE_DATA', null);
    commit('SET_ODDS_MGMT_EDIT_MODE_DATA', null);
  },
  setOddsMgmtRow: ({ commit }, payload) => {
    commit('SET_ODDS_MGMT_ROW', payload);
  },
  updateEditModeData: ({ commit }, payload) => {
    commit('UPDATE_EDIT_MODE_DATA', payload);
  },
  setCreateNewSelection: ({ commit, dispatch, state }, payload) => {
    const url = `${state.discoverServerLocation.productApi.url}${process.env.VUE_APP_PRINT_SUPPORTED_TEMPLATES}`;
    const headers = createHeaders(state.discoverServerLocation, state.user);
    if (payload) {
      dispatch('setActiveLoader', true);
      getEdit(url, headers)
        .then((res) => {
          commit('SET_PRINT_SUPPORTED_TEMPLATES', res.data);
          commit('SET_CREATE_NEW_SELECTION', true);
          dispatch('setActiveLoader', false);
        })
        .catch(() => {
          commit('SET_CREATE_NEW_SELECTION', false);
          dispatch('setActiveLoader', false);
        });
    } else {
      commit('SET_CREATE_NEW_SELECTION', false);
    }
  },
  setCustomOfferSelectionStatus: ({ commit }, payload) => {
    commit('SET_CUSTOM_OFFER_SELECTION_STATUS', payload);
  },
  saveNewSelection: async ({ dispatch, state }, payload) => {
    try {
      const headers = createHeaders(state.discoverServerLocation, state.user);
      // eslint-disable-next-line no-unused-vars
      const response = await updateEdit(`${state.discoverServerLocation.productApi.url}/createOfferTemplate`, payload, headers);
      dispatch('setNotificationMessage', {
        title: 'general_success',
        description: 'admin_templateCreated',
        rulesActions: false,
      });
      EventBus.$emit('resetActiveModule', 'offer-templates');
    } catch (e) {
      dispatch('setNotificationMessage', {
        title: 'general_error',
        description: 'admin_templateCreatedFailed',
        rulesActions: false,
      });
    }
  },
  setCreateRiskRule: ({ commit, dispatch, state }) => {
    const url = `${state.discoverServerLocation.productApi.url}${process.env.VUE_APP_RISK_RULE_BASE_DATA}`;
    const headers = createHeaders(state.discoverServerLocation, state.user);
    dispatch('setActiveLoader', true);
    return getEdit(url, headers)
      .then((res) => {
        dispatch('setActiveLoader', false);
        return res.data;
      })
      .catch(() => {
        commit('SET_CREATE_RISK_RULE', false);
        dispatch('setActiveLoader', false);
        return null;
      });
  },
  saveRiskRule: async ({ dispatch, state }, payload) => {
    try {
      const headers = createHeaders(state.discoverServerLocation, state.user);
      // eslint-disable-next-line no-unused-vars
      const response = await updateEdit(`${state.discoverServerLocation.productApi.url}/createRiskRule`, payload, headers);
      dispatch('setNotificationMessage', {
        title: 'general_success',
        description: 'admin_riskRuleCreated',
        rulesActions: false,
      });
      EventBus.$emit('resetActiveModule', 'risk-management');
    } catch (e) {
      dispatch('setNotificationMessage', {
        title: 'general_error',
        description: 'admin_riskRuleCreatedFailed',
        rulesActions: false,
      });
    }
  },
  deleteRiskRule: async ({ dispatch, state }, payload) => {
    try {
      const headers = createHeaders(state.discoverServerLocation, state.user);
      // eslint-disable-next-line no-unused-vars
      const response = await updateEdit(`${state.discoverServerLocation.productApi.url}/deleteRiskRule`, payload, headers);
      dispatch('setNotificationMessage', {
        title: 'general_success',
        description: 'admin_riskRuleDelete',
        rulesActions: false,
      });
      EventBus.$emit('resetActiveModule', 'risk-management');
    } catch (e) {
      dispatch('setNotificationMessage', {
        title: 'general_error',
        description: 'admin_riskRuleDeleteFailed',
        rulesActions: false,
      });
    }
  },
  setConfirmAction: ({ commit }, payload) => {
    commit('SET_CONFIRM_ACTION', payload);
  },
  setCreateRiskActive: ({ commit }, payload) => {
    commit('SET_CREATE_RISK_RULE', payload);
  },
};
